import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/tape-tap",
  "date": "2016-05-27",
  "title": "TAPE & TAP",
  "author": "admin",
  "tags": ["development", "javascript", "testing"],
  "featuredImage": "feature.jpg",
  "excerpt": "Tape & Tap are testing tools you can use instead of Mocha or Jasmine. The Test Anything Protocol (TAP) is a definition that has been around since 1987 and Tape is a test harness written in JavaScript for Node and the browser."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Why not Mocha, Jasmine etc?`}</h2>
    <p>{`Even though hugely popular, Mocha and Jasmine have their own gotchas.`}</p>
    <h3>{`Globals`}</h3>
    <p>{`Global functions like `}<inlineCode parentName="p">{`describe`}</inlineCode>{` and `}<inlineCode parentName="p">{`it`}</inlineCode>{` pollute the global environment.`}</p>
    <h3>{`Too much Configuration`}</h3>
    <p>{`Mocha needs an assertion library and also a reporting library, and then extra parameters to be passed on to Mocha for transpiling if the tests were written in ES6.`}</p>
    <h3>{`Shared State`}</h3>
    <p>{`Functions called before and after like beforeEach and afterEach would encourage sharing state between tests. But Tape would use setup and teardown functions for individual tests, the state will be retained in the local variables.`}</p>
    <h2>{`TAP`}</h2>
    <p>{`Tap formats test results in an easy to read format:`}</p>
    <pre><code parentName="pre" {...{}}>{`TAP version 13
# equivalence
ok 1 these two numbers are equal
 
1..1
# tests 1
# pass  1
 
# ok
`}</code></pre>
    <p>{`TAP also allows us to pipe results generated from other tools following TAP protocol.`}</p>
    <h2>{`Example`}</h2>
    <p>{`Before we start, we need to install tape, so we can run tests in Node.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm install tape -g
`}</code></pre>
    <p>{`You don’t need to install tape globally, if you are not running tests in command line. Ideally you should have `}<inlineCode parentName="p">{`tape`}</inlineCode>{` and `}<inlineCode parentName="p">{`tap-sepc`}</inlineCode>{` installed as `}<inlineCode parentName="p">{`devDependencies`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`npm install tape tap-spec ––save–dev
`}</code></pre>
    <p>{`Now let’s look at a simple example on how to use Tape.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var test = require('tape');
 
test('Equation test', function (t) {
    t.equal(1, 1, '1 should be equal to 1');
    t.end();
});
`}</code></pre>
    <p>{`We bring in the Tape module using require. Assuming you have Node environment setup, we can run the tests in command line.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`tape tests/**/*.js
`}</code></pre>
    <p>{`If you compared the same test with mocha you will realise that you will need to write more code with `}<inlineCode parentName="p">{`describe`}</inlineCode>{` and `}<inlineCode parentName="p">{`it`}</inlineCode>{` methods.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var assert = require("assert");
 
describe('Equation test', function(){
  it('1 should be equal to 1', function(){
    assert.equal(1, 1);
  })
})
`}</code></pre>
    <p>{`Unlike Mocha, Tape doesn’t need a test runner. You can run tests as modules with Node.`}</p>
    <p>{`Less abstraction means Tape code is easier to read and understand.`}</p>
    <p>{`ES6
If you are like me and love write your JavaScript code in the latest and the greatest features ES6 has to offer, you can do so with the help of Babel via a require hook called `}<inlineCode parentName="p">{`babel-register`}</inlineCode>{`.`}</p>
    <p>{`Now we can use ES6 features to write tests.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var test = require('tape');
 
test('Equation test', (t) => {
    t.equal(1, 1, '1 should be equal to 1');
    t.end();
});
`}</code></pre>
    <p>{`To run tests, now we need the `}<inlineCode parentName="p">{`babel-register`}</inlineCode>{` flag.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-sh"
      }}>{`tape -r babel-register tests/test02.js
`}</code></pre>
    <p>{`The `}<inlineCode parentName="p">{`-r`}</inlineCode>{` flags makes sure babel-register loads before running any tests, to allow for JIT compilation.`}</p>
    <h2>{`Conclusion`}</h2>
    <p>{`The code snippets for this post can be found on `}<a parentName="p" {...{
        "href": "https://github.com/szaranger/tape-tap-intro"
      }}>{`Github`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      